import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import PageLoader from './components/Common/PageLoader'
import VueElementLoading from "vue-element-loading";
import ServerError from './components/Common/500'
Vue.component("VueElementLoading", VueElementLoading);
import { VueEditor } from "vue2-editor";
Vue.component('VueEditor', VueEditor)
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
Vue.component(VueCropper);
import CryptoJS from 'crypto-js';
Vue.use(CryptoJS)
import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);
Vue.component('PageLoader', PageLoader)
Vue.component('ServerError', ServerError)
Vue.config.productionTip = false

window.axios = require('axios')
window.axios = require('axios')


axios.defaults.baseURL = 'https://naturecampapi.leopardtechlabs.in/'
Vue.prototype.baseURL = "https://naturecampapi.leopardtechlabs.in/"
Vue.prototype.mediaURL = "https://naturecampapi.leopardtechlabs.in/file/get/"
Vue.prototype.mediaURLnew = "https://naturecampapi.leopardtechlabs.in/wp?key="




// axios.defaults.baseURL = 'https://naturecampapi.forest.kerala.gov.in'
// Vue.prototype.baseURL = "https://naturecampapi.forest.kerala.gov.in"
// Vue.prototype.mediaURL = "https://naturecampapi.forest.kerala.gov.in/file/get/"
// Vue.prototype.mediaURLnew = "https://naturecampapi.forest.kerala.gov.in/wp?key=" 


//sajan-->>

// window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.168.49.91:4001'
// Vue.prototype.baseURL = "http://192.168.49.91:4001"
// Vue.prototype.mediaURL = "http://192.168.49.91:4001/file/get?key="
// Vue.prototype.mediaURLnew = "http://192.168.49.91:4001/wp?key="



// window.axios = require('axios')
// axios.defaults.baseURL = 'http://localhost:4001'
// Vue.prototype.baseURL = "http://localhost:4001"
// Vue.prototype.mediaURL = "http://localhost:4001/file/get/"
// Vue.prototype.mediaURLnew = "http://localhost:4001/wp?key="



new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')